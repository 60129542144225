import { ROUTES } from "@src/constants/routes"

export const SIDE_BAR_CONFIG = [
  {
    id: 1,
    label: "Quản lý đơn hàng",
    iconName: "package",
    path: ROUTES.PROFILE_ORDERS
  },
  {
    id: 2,
    label: "Thông báo",
    iconName: "notification",
    path: ROUTES.PROFILE_NOTIFICATION
  },
  {
    id: 3,
    label: "Sản phẩm yêu thích",
    iconName: "heart",
    path: ROUTES.PROFILE_FAVORITE_PRODUCTS
  },
  {
    id: 4,
    label: "Thông tin tài khoản",
    iconName: "setting",
    path: ROUTES.PROFILE_INFO
  },
  {
    id: 5,
    label: "Sổ địa chỉ",
    iconName: "location",
    path: ROUTES.PROFILE_ADDRESS
  },
  {
    id: 6,
    label: "Ví voucher",
    iconName: "voucher-2",
    path: ROUTES.PROFILE_VOUCHER
  }
]

export const SIDE_BAR_DEVICE_CONFIG = [
  {
    id: 1,
    label: "Quản lý đơn hàng",
    iconName: "package",
    path: ROUTES.DEVICE_ORDERS
  },
  {
    id: 2,
    label: "Sổ địa chỉ",
    iconName: "location",
    path: ROUTES.DEVICE_ADDRESS
  },
]