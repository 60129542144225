"use client";

import React, { useMemo } from "react";
import { Box } from "@mui/material";
import FooterDesktop from "./footer-desktop";
import FooterMobile from "./footer-mobile";
import { useLayoutContext } from "@src/layouts/provider";
import { ROUTES } from "@src/constants";
const Footer = () => {
  const {
    config
  } = useLayoutContext();
  const branches = useMemo(() => {
    return (config?.branches || []).map(branch => ({
      title: branch.name,
      href: `${ROUTES.BRANCH}?id=${branch.id}`
    }));
  }, [config]);
  return <Box component="footer" data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <Box sx={{
      display: {
        xs: "block",
        lg: "none"
      }
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <FooterMobile branches={branches} data-sentry-element="FooterMobile" data-sentry-source-file="index.tsx" />
      </Box>
      <Box sx={{
      display: {
        xs: "none",
        lg: "block"
      }
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <FooterDesktop branches={branches} data-sentry-element="FooterDesktop" data-sentry-source-file="index.tsx" />
      </Box>
    </Box>;
};
export default Footer;