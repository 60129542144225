import React from "react";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "next/link";
import { Icons } from "@src/components";
const CollapseFooterItem = ({
  title,
  items
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return <Box data-sentry-element="Box" data-sentry-component="CollapseFooterItem" data-sentry-source-file="collapse-footer-item.tsx">
      <Button onClick={handleClick} disableRipple sx={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      outline: "none",
      textTransform: "uppercase",
      color: "#141414",
      fontWeight: "normal",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }} data-sentry-element="Button" data-sentry-source-file="collapse-footer-item.tsx">
        {title}
        <Icons name="arrow-down" sx={{
        transform: open ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.15s ease"
      }} data-sentry-element="Icons" data-sentry-source-file="collapse-footer-item.tsx" />
      </Button>
      <Collapse in={open} data-sentry-element="Collapse" data-sentry-source-file="collapse-footer-item.tsx">
        <Stack flexDirection="column" mb="16px" sx={{
        "& a": {
          color: "#6D6D6D",
          textDecoration: "none",
          fontSize: "14px",
          fontWeight: "normal",
          marginLeft: "16px !important"
        }
      }} gap="12px" data-sentry-element="Stack" data-sentry-source-file="collapse-footer-item.tsx">
          {items.map((item, index) => <Link key={index} href={item.href}>
              {item.title}
            </Link>)}
        </Stack>
      </Collapse>
    </Box>;
};
export default CollapseFooterItem;