import { ROUTES } from "./routes"

export const COLLECTION = {
  NEW_IN: "new_arrival",
  ON_SALE: "on_sale",
  BEST_SELLER: "best_seller",
  SIENA: "siena",
  ALL: "all"
}

export const CONFIG_COLLECTION = {
  NEW_IN: {
    label: "New arrival",
    url: ROUTES.NEW_IN,
    key: COLLECTION.NEW_IN
  },
  BEST_SELLER: {
    label: "Best Seller",
    url: ROUTES.BEST_SELLER,
    key: COLLECTION.BEST_SELLER
  },
  ON_SALE: {
    label: "On Sale",
    url: ROUTES.ON_SALE,
    key: COLLECTION.ON_SALE
  }
}

export const PRODUCT_PAGE_SIZE = 20
