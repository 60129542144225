import * as React from "react";
import Stack from "@mui/material/Stack";
const WrapperLinkHover = ({
  title,
  children,
  alignItems = "center",
  justifyContent = "center"
}) => {
  const containerRef = React.useRef(null);
  const subRef = React.useRef(null);
  React.useEffect(() => {
    if (containerRef.current && subRef.current) {
      containerRef.current.style.width = `${subRef.current.clientWidth}px`;
    }
  }, []);
  return <Stack ref={containerRef} height="100%" alignItems={alignItems} justifyContent={justifyContent} sx={{
    whiteSpace: "nowrap",
    position: "relative",
    zIndex: 2
  }} style={{
    ...(subRef.current?.clientWidth && {
      width: subRef.current?.clientWidth
    })
  }} data-sentry-element="Stack" data-sentry-component="WrapperLinkHover" data-sentry-source-file="index.tsx">
      {children}
      <div ref={subRef} style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      visibility: "hidden"
    }} />
    </Stack>;
};
export default WrapperLinkHover;